.switch {
  box-sizing: content-box;
  margin-left: -20px;
}

.switch i {
  display: inline-block;
  cursor: pointer;
  margin-left: -20px;
  margin-bottom: -3px;
  padding-right: 20px;
  transition: all ease 0.2s;
  -webkit-transition: all ease 0.2s;
  border-radius: 20px;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}

.switch i:before {
  display: block;
  content: "";
  width: 30px;
  height: 30px;
  padding: 1px;
  border-radius: 20px;
  background: white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.switch :checked + i {
  padding-right: 0;
  padding-left: 20px;
  background: rgb(100, 189, 99);
}

.hw40 {
  width: 40px;
  height: 40px;
}

.h100 {
  height: 100%;
}

.h25 {
  height: 25px;
}

.w100 {
  width: 100%;
}

.wh40 {
  width: 2.5rem;
  height: 2.5rem;
}

.wh1rem {
  width: 1rem;
  height: 1rem;
}

.mlc {
  margin-left: auto;
  margin-right: auto;
}

.ml10 {
  margin-left: 10px;
}

.ml20 {
  margin-left: 20px;
}

.ml25 {
  margin-left: 25px;
}

.ml30 {
  margin-left: 30px;
}

.ml30n {
  margin-left: -30px;
}

.ml35 {
  margin-left: 35px;
}

.ml40 {
  margin-left: 40px;
}

.ml50 {
  margin-left: 50px;
}

.mr10 {
  margin-right: 10px;
}

.mlr10 {
  margin-left: 10px;
  margin-right: 10px;
}

.mlr15 {
  margin-left: 15px;
  margin-right: 15px;
}

.mlr20 {
  margin-left: 20px;
  margin-right: 20px;
}

.mlr30 {
  margin-left: 30px;
  margin-right: 30px;
}

.mlr40 {
  margin-left: 40px;
  margin-right: 40px;
}

.mlrauto {
  margin-left: auto;
  margin-right: auto;
}

.mr20 {
  margin-right: 20px;
}

.mr25 {
  margin-right: 25px;
}

.ml15n {
  margin-left: -15px;
}

.ml20n {
  margin-left: -20px;
}

.mt5n {
  margin-top: -5px;
}

.mb5 {
  margin-bottom: 5px;
}

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb40 {
  margin-bottom: 40px;
}

.mtb40 {
  margin-top: 40px;
  margin-bottom: 40px;
}

.mtbauto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt5 {
  margin-top: 5px;
}

.mt7 {
  margin-top: 7px;
}

.mt10 {
  margin-top: 10px;
}

.mt10n {
  margin-top: -10px;
}

.mt15n {
  margin-top: -15px;
}

.mt20 {
  margin-top: 20px;
}

.mt20n {
  margin-top: -20px;
}

.mt30p {
  margin-top: 30%;
}

.mt25 {
  margin-top: 25px;
}

.mt40 {
  margin-top: 40px;
}

.mt70 {
  margin-top: 70px;
}

.txt-gap {
  margin-right: 5px;
}

.ptb10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.plr10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.plr20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.pt10 {
  padding-top: 10px;
}

.pt20 {
  padding-top: 20px;
}

.pb20 {
  padding-bottom: 20px;
}

.pb40 {
  padding-bottom: 40px !important;
}

.mlr15n {
  margin-left: -15px;
  margin-right: -15px;
}

.floatl {
  float: left;
}

.floatr {
  float: right;
}

.alignr {
  text-align: right;
}

.h-divider {
  width: 100%;
  border-bottom: 3px solid #778899;
}

.h-thin-divider {
  width: 100%;
  border-bottom: 1px solid gray;
  padding-bottom: 10px;
}

.h-thin-divider-np {
  width: 100%;
  border-bottom: 1px solid gray;
}

.h-border {
  width: 100%;
  border: 1px solid gray;
  padding-bottom: 10px;
}

.h-border-right {
  width: 100%;
  border-right: 1px solid gray;
}

.h-border-top {
  width: 100%;
  border-top: 1px solid gray;
}

.form-inline-error {
  color: $danger;
  font-size: $font-size-sm;
  border-color: $danger;
  @include box-shadow(inset 0 1px 1px rgba($danger, 0.075));
  margin-bottom: 5px;
}

input.parsley-error {
  border-color: $danger;
  @include box-shadow(inset 0 1px 1px rgba($danger, 0.075));
}

.group-check.parsley-error {
  border-color: $danger;
  @include box-shadow(inset 0 1px 1px rgba($danger, 0.075));
}

select.parsley-error {
  border-color: $danger;
  @include box-shadow(inset 0 1px 1px rgba($danger, 0.075));
}

.parsley-errors-list {
  font-size: $font-size-sm;
  padding-left: 0;
  margin-bottom: 5px;

  li {
    list-style: none;
    color: $danger;
    font-size: 0.9rem;
    padding-top: 5px;
  }
}

.tl-large-text {
  height: 2.6rem;
}

.pull-right{
  float: right;
}

.tl-word-break {
  word-break: break-word;
  word-wrap: break-word;
}

.tl-input-inline-errors {
  font-size: $font-size-sm;
  padding-left: 0;
  margin-bottom: 5px;
  margin-top: 5px;
  color: $danger;
}

.tl-clear-input {
  position: absolute;
  display: inline-block;
  top: 38px;
  right: 15px;
}

.tl-for-dropdown {
  right: 35px;
}

.tl-relative {
  position: relative;
}

.tl-cursor-pointer {
  cursor: pointer;
}

/**
 * Jasny Bootstrap
 * Fileinput.less
 */

.fileinput.fileinput-new {
  .thumbnail {
    padding: $thumbnail-padding;
    line-height: $line-height-base;
    background-color: $thumbnail-bg;
    border: $thumbnail-border-width solid $thumbnail-border-color;
    border-radius: $thumbnail-border-radius;
    transition: all 0.2s ease-in-out;
    @include box-shadow(0 1px 2px rgba(0, 0, 0, 0.075));
  }
}

.fileinput-preview.fileinput-exists {
  border: 1px solid $input-border-color;
  border-radius: $border-radius;
  padding: 5px;
}

.fileinput.input-group {
  display: flex;
}

.fileinput-new.input-group .btn-file,
.fileinput-new .input-group .btn-file {
  border-radius: 0 $border-radius $border-radius 0;

  &.btn-xs,
  &.btn-sm {
    border-radius: 0 $border-radius-sm $border-radius-sm 0;
  }

  &.btn-lg {
    border-radius: 0 $border-radius-lg $border-radius-lg 0;
  }
}

.fc {
  font-family: "Courier New", Courier, monospace;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin-top: $spacer-y;
  margin-bottom: $spacer-y;
  @include border-radius();

  > li {
    display: inline; // Remove list-style and block-level defaults

    &:first-child {
      a {
        margin-left: 0;
        @include border-left-radius($border-radius);
      }
    }

    &:last-child {
      a {
        @include border-right-radius($border-radius);
      }
    }

    &.active a {
      @include plain-hover-focus {
        z-index: 2;
        cursor: default;
        border-color: $pagination-active-border;
      }
    }

    &.disabled a {
      @include plain-hover-focus {
        cursor: $cursor-disabled;
        border-color: $pagination-disabled-border;
      }
    }
  }

  > li > a {
    position: relative;
    float: left; // Collapse white-space
    padding: $pagination-padding-y $pagination-padding-x;
    margin-left: -1px;
    line-height: $line-height-base;
    text-decoration: none;

    @include hover-focus {
      border-color: $pagination-hover-border;
    }
  }
}

.tl-custom-pagination {
  font-size: 14px;

  .ngx-pagination {
    margin-bottom: 0;

    a,
    button {
      padding: 0.1875rem 0.25rem;
    }

    li {
      margin-left: 6px;
      padding-left: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      padding-right: 0px;
      min-width: 15px;
      text-align: center;

      a {
        color: $fda-blue;
      }
    }

    .current {
      display: inline-block;
      border-radius: 2px;
      background-color: $fda-blue;
      color: $fda-white;
      padding-left: 3px;
      padding-right: 3px;
      min-width: 10px;
    }
  }
}

.rightalign {
  text-align: right;
}

.nopad {
  padding-top: 0px;
  padding-bottom: 0px;
}

/*Business Card Css */
.business-card {
  border: 1px solid #cccccc;
  background: #f8f8f8;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 10px;
}

.profile-img {
  height: 120px;
  background: white;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

.trlm_log {
  display: block;
  height: auto;
  width: 52px;
  padding-top: 5px;
  margin-right: 15px;
}

$blue-gray: #34495e;
$blue-gray-darken: #2c3e50;
$blue: #3498db;
$blue-darken: #2980b9;
$green: #1abc9c;
$green-darken: #16a085;

// .breadcrumb {
//   list-style: none;
//   display: inline-block;

//   .icon {
//     font-size: 14px;
//   }

//   li {
//     float: left;
//     a {
//       color: #fff;
//       display: block;
//       background: $blue;
//       text-decoration: none;
//       position: relative;
//       height: 40px;
//       line-height: 40px;
//       padding: 0 10px 0 5px;
//       text-align: center;
//       margin-right: 23px;
//     }
//     &:nth-child(even) {
//       a {
//         background-color: $blue-darken;

//         &:before {
//           border-color: $blue-darken;
//           border-left-color: transparent;
//         }
//         &:after {
//           border-left-color: $blue-darken;
//         }
//       }
//     }
//     &:first-child {
//       a {
//         padding-left: 15px;
//         @include border-radius(4px 0 0 4px);
//         &:before {
//           border: none;
//         }
//       }
//     }
//     &:last-child {
//       a {
//         padding-right: 15px;
//         @include border-radius(0 4px 4px 0);
//         &:after {
//           border: none;
//         }
//       }
//     }

//     a {
//       &:before,
//       &:after {
//         content: "";
//         position: absolute;
//         top: 0;
//         border: 0 solid $blue;
//         border-width: 20px 10px;
//         width: 0;
//         height: 0;
//       }
//       &:before {
//         left: -20px;
//         border-left-color: transparent;
//       }
//       &:after {
//         left: 100%;
//         border-color: transparent;
//         border-left-color: $blue;
//       }
//       &:hover {
//         background-color: $green;

//         &:before {
//           border-color: $green;
//           border-left-color: transparent;
//         }
//         &:after {
//           border-left-color: $green;
//         }
//       }
//       &:active {
//         background-color: $green-darken;

//         &:before {
//           border-color: $green-darken;
//           border-left-color: transparent;
//         }
//         &:after {
//           border-left-color: $green-darken;
//         }
//       }
//     }
//   }
// }

.showlink {
  color: #218bc3;
}

.trlm-container {
  max-width: 100% !important;

  a:not([href]):not([tabindex]):hover,
  a:not([href]):not([tabindex]):focus {
    text-decoration: underline;
  }
}

.rq {
  background-color: lightyellow;
}

.green-check {
  color: green;
  font-weight: bold;
  font-size: 20px;
}

.white-check {
  font-size: 20px;
}

.green-check-45 {
  color: green;
  font-weight: bold;
  font-size: 45px;
}

.red-cross {
  color: red;
  font-weight: bold;
}

.red-cross-45 {
  color: red;
  font-weight: bold;
  font-size: 45px;
}

.orange-warning {
  color: $fda-yellow;
  font-weight: bold;
  font-size: 20px;
}

.blue-status {
  color: blue;
  font-weight: bold;
  font-size: 20px;
}

.tl-certified {
  color: $fda-green;
}

.tl-expired-st,
.tl-inactive-st {
  color: $fda-grey;
}

.tl-draft-st {
  color: $fda-blue;
}

.tl-active-st {
  color: $fda-green;
}

.tl-pending-st {
  color: $fda-purple;
}

.tl-fda-review-st,
.tl-fda-editing-st {
  color: $fda-yellow;
}

.tl-need-review-st {
  color: $fda-red;
}

.need-review {
  color: $fda-red;
}

.error-icon {
  color: $fda-red;
}

.warning-icon {
  color: $fda-yellow;
}


.tl-blue-icon {
  color: $fda-blue;
}

.tl-strong {
  font-weight: bold;
}

.widget-light {
  border: 1px solid $fda-blue-grey;
  box-shadow: none;
}

.select2-selection__rendered {
  line-height: calc(1.5em + 0.75rem + 2px) !important;
}

.select2-container .select2-selection--single {
  height: calc(1.5em + 0.75rem + 2px) !important;
}

.select2-selection__arrow {
  height: calc(1.5em + 0.75rem) !important;
}

.select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  padding: 0rem 1.3rem !important;
}

.invalid-multiselect {
  .c-btn {
    border-color: $fda-red;
  }
}

.warn-color {
  color: $fda-warn-yellow;
}

.int-conversion-tab,
.register-product-tab {
  .nav-tabs {
    background-color: transparent !important;
    border-bottom: 0px solid #c5cbdd !important;

    & > .nav-item {
      margin-bottom: 0px;
      margin-right: 2rem;

      &:last-child {
        margin-right: 0rem;
      }

      & .nav-link {
        background-color: transparent;
        border-bottom: 3px solid #c5cbdd;
        color: $fda-medium-blue;
        text-align: left;
        padding-left: 0px;

        &.active {
          background-color: transparent;
          border-bottom: 3px solid #007cba !important;
          color: #000000;
        }

        &.active:hover,
        &.active:focus {
          background-color: transparent;
        }
      }
    }
  }

  .tab-content {
    background-color: transparent;

    & > .tab-pane {
      padding: 0rem 0rem;
      margin: 2rem 0rem;
    }
  }
}

.btn-h30 {
  height: 30px;
}

/******** custom input component *****/
.tl-custom-control {
  position: relative;

  & .tl-custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    box-sizing: border-box;
    padding: 0;
    top: 0px;
    left: 0px;

    &:checked ~ .tl-custom-control-label::before {
      border: none;
      background-color: $fda-light-blue;
      box-shadow: none;
    }

    &:checked ~ .tl-custom-control-label::after {
      background-image: none;
      // content: "\f00c";
      // font-family: 'Font Awesome\ 5 Free';
      // font-weight: 900;
      color: $fda-blue;
      text-align: center;
      font-size: 14px;
      background-size: 14px;
      background-position: center center;
      background-position-x: center;
      background-position-y: center;
      background-repeat: no-repeat;
      // background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
      background-image: url("../assets/icon/check_blue.svg");
    }

    &:focus ~ .tl-custom-control-label::before {
      box-shadow: 0px 0px 2px 1px rgba(0, 124, 186, 0.5);
    }

    &:focus:not(:checked) ~ .tl-custom-control-label::before {
      border-color: $fda-blue-grey;
    }

    //&:not(:disabled):active ~ .tl-custom-control-label::before {}

    &:disabled {
      ~ .tl-custom-control-label {
        color: $gray-600;

        &::before {
          background-color: $gray-400;
        }
        &::after {
          color: $gray-600;
          background-image: url("../assets/icon/check_grey.svg");
        }
      }
    }
  }

  & .tl-custom-control-label {
    line-height: 21px;
    min-height: 21px;
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
    cursor: pointer;
    padding-left: 2rem;

    &::before {
      border-radius: 1px;
      height: 21px;
      width: 21px;
      line-height: 21px;
      top: 0px;
      left: 0px;
      content: "";
      position: absolute;
      display: block;
      pointer-events: none;
      border-radius: 1px;
      border: 1px solid $gray-500;
      cursor: pointer;
    }

    &::after {
      position: absolute;
      top: 0px;
      left: 0px;
      display: block;
      width: 21px;
      height: 21px;
      line-height: 21px;
      content: "";
    }
  }

  & .tl-radio {
    & ~ .tl-custom-control-label::before {
      border-radius: 50%;
    }

    & ~ .tl-custom-control-label::before:hover {
      border: 1px solid $fda-blue;
    }

    & ~ .tl-custom-control-label::after {
      top: 7px;
      left: 7px;
      width: 7px;
      height: 7px;
    }

    &:checked ~ .tl-custom-control-label::after {
      background-image: none;
      content: "";
      border: 1px solid $fda-blue;
      border-radius: 50%;
      background-color: $fda-blue;
    }

    &:checked:disabled {
      ~ .tl-custom-control-label {
        &::after {
          background-color: $gray-600;
          border: 1px solid $gray-600;
        }
      }
    }
  }
}
/******** custom input component *****/

.tl-datefield-icon {
  display: inline-block;
  position: absolute;
  right: 21px;
  top: 35px;
  height: 21px;
  width: 21px;
  font-size: 21px;
  color: $black;

  &.tl-no-label {
    top: 5px;
  }
}

/***  Multi select Overlay for Mutiple value Fields ***/
.tl-multi-select::before {
  position: absolute;
  top: 38px;
  left: 30px;
  content: "[Multiple]";
  font-weight: normal;
  display: inline-block;
}

.tl-input-field:focus ~ .tl-multi-select::before {
  display: none;
}

.tl-bgw {
  background-color: $fda-white;
}

.select2-selection--multiple {
  border: 1px solid $gray-400 !important;

  .select2-selection__rendered li {
    line-height: 22px;
  }
}

/**** custom modal  ***/
.tl-moal-md {
  max-width: 650px;
}

.modal-title-bar {
  background-color: #4175ab;
  color: #ffff;
}

.tl-modal-lg {
  @media (min-width: 576px) {
    max-width: 85%;
  }
  @media (min-width: 992px) {
    max-width: 800px;
  }
}

.tl-modal-x-lg {
  @media (min-width: 576px) {
    max-width: 85%;
  }
  @media (min-width: 992px) {
    max-width: 992px;
  }
}

.tl-modal {
  & .close_btn {
    position: absolute;
    top: 21px;
    right: 21px;
  }

  &__header,
  &__footer {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  &__scrolldiv {
    max-height: calc(100vh - 300px);
    overflow-y: auto;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: 1.875rem;
    margin-right: 1.875rem;
  }

  &__control_label {
    font-weight: $font-weight-bold;
  }

  &__rowselection {
    border: 1px solid $fda-blue;
    background-color: $fda-light-blue;
    padding: 1rem;
  }
}

/***** register styles ******/

.tl-regwizard-footer {
  margin-bottom: -90px;
  min-height: 90px;
}

.tl-dropdown-menu {
  min-width: 9rem;

  & > li > a,
  & > li > a:not([href]):not([tabindex]) {
    padding-top: 7px;
    padding-bottom: 7px;
    color: $fda-blue;
    font-weight: 500;
  }

  & > li > a:hover {
    font-weight: 600;
  }

  & > li > a:not([href]):not([tabindex]) {
    color: $fda-blue;
  }
}

.tl-muted-dropdown-info {
  display: block;
  color: $gray-700;
  padding: 0rem 1.5rem;
  margin-left: 1.3rem;
  margin-bottom: 5px;
  margin-top: -5px;

  &:before {
    background-image: none;
    font-family: "Font Awesome\ 5 Free";
    font-weight: 900;
    content: "\f05a";
    color: $fda-blue;
    text-align: center;
    font-size: 1rem;
    position: absolute;
    left: 1.4rem;
    display: block;
    width: 1rem;
    height: 1rem;
    line-height: 1rem;
    margin-top: 2px;
  }

  &.warning:before {
    content: "\f071";
    color: $fda-yellow;
  }
  &.error:before {
    content: "\f06a";
    color: $fda-red;
  }
}

/*****  filter section ********/

.tl-filter-info-panel {
  display: block;
  position: relative;
  background: $fda-white;
  border: 1px solid $gray-light;
  margin-top: 20px;

  &:before,
  &:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 100%;
    width: 0;
    height: 0;
  }

  &:before {
    left: 78%;
    border: 11px solid transparent;
    border-bottom-color: $gray-light;
  }

  &:after {
    left: calc(78% + 1px);
    border: 10px solid transparent;
    border-bottom-color: $fda-white;
  }
}

/******* Grid Specific Styles ****/
.tl-cell-link .btn {
  line-height: 1rem;
  font-weight: normal;
  text-align: left;
  padding: 0px;
  height: auto;
  font-size: 0.875rem;
}

.tl-grid-action-drop {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  min-width: auto;
  height: 25px;
  line-height: 14px;
}

.tl-grid-icons {
  height: 20px;
  width: 20px !important;
  vertical-align: middle;
  margin-right: 5px;
}

.modal-title {
  text-transform: capitalize;
}

.tl-left-nav{
  ul{
    list-style: none;
    padding-left: 0px;
  }

}

.tl-custom-multi-select .c-btn{
  font-size: .95rem !important; 
  border-radius: .3rem !important;
  color: $fda-grey !important;
}

.tl-announcement-box {
  border: 1px solid $fda-blue;
  background-color: $fda-light-blue;
  padding: 20px 25px 20px 50px;
  margin-bottom: 30px;
  font-size: 15px;
  position: relative;

  .announcement-icon{
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 16px;
  }

  .close-action{
    height: auto;
    cursor: pointer;
    position: absolute;
    top: 12px;
    right: 12px;
  }
}

.tl-warning{
  border: 1px solid $fda-yellow;
  background-color: #fcf0d1;
}

.tl-tags{
  height: 30px;
  margin-right: 10px;
  padding: 5px 15px;
  line-height: 20px ;
  font-size: 16px;
  font-weight: normal;
  display:inline-block;
  background: $gray-300;
    color: $fda-black;
}

.tl-tag-icon{
  margin-left: 25px;
  color: $fda-blue;
}

.tl-text-muted{
  color: $fda-grey;
}

.tl-dark-btn{
  color: $fda-medium-blue;
}