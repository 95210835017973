// grid them overrides
.ag-theme-bootstrap {
  font-family: $font-family-base;
  font-size: $font-size-base;
}

.ag-theme-bootstrap .ag-header {
  font-family: $font-family-base;
}

.ag-theme-bootstrap .ag-cell {
  line-height: 21px;
  min-height: $ag-grid-row-height;
  font-size: $font-size-base;
}

.ag-theme-bootstrap .ag-cell-value {
  font-size: $font-size-base;
}

.ag-theme-bootstrap .ag-header-row {
  font-weight: bold;
  background-color: $fda-white;
  border-bottom: 1px solid #BDC3C7;
  font-size: $font-size-base;
}

.ag-theme-bootstrap .ag-header-cell,
.ag-theme-bootstrap .ag-header-group-cell {
  border-style: solid;
  border-color: #BDC3C7;
  padding-left: 12px;
  padding-right: 12px;
  border-width: 0;
  font-size: $font-size-base;
}

.ag-theme-bootstrap .ag-row-odd {
  background-color: $fda-white;
}

.ag-theme-bootstrap .ag-row-selected {
  background-color: $fda-light-blue-grey;
}

/*
 * The override should be placed after the import of the theme.
 * Alternatively, you can aso increase the selector's specificity.
 */
.ag-theme-bootstrap .ag-icon-expanded {
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  font-size: 1rem;
  color: $gray-700;
}

.ag-theme-bootstrap .ag-icon-expanded:before {
  content: $ag-icon-expanded;
}

.ag-theme-bootstrap .ag-icon-contracted {
  font-family: "Font Awesome 5 Free";
  font-weight: bold;
  font-size: 1rem;
  color: $gray-700;
}

.ag-theme-bootstrap .ag-icon-contracted:before {
  content: $ag-icon-contracted;
}

.cell-wrap-text {
  white-space: normal !important;

  .ag-cell-value {
    font-size: 1rem;    
    word-break: break-word;
    word-wrap: break-word;
  }

}

.ag-theme-bootstrap .ag-row {
  min-height: $ag-grid-row-height;
  border-bottom: none !important; //1px solid #BDC3C7 !important;
}

.ag-row .ag-cell {
  display: flex;
  justify-content: left;
  /* align horizontal */
  align-items: center;
}

.tl-cell-center {
  justify-content: center !important;

  .ag-cell-wrapper {
    justify-content: center !important;
  }
}

.ag-header-cell-label {
  justify-content: left;
}

.header-wrap-text {

  & .ag-header-cell-label {
    justify-content: left;

    & .ag-header-cell-text {
      white-space: normal !important;
    }
  }
}

.tl-wrap-text {
  white-space: normal !important;
}

.tl-pinned-grid {
  .not-pinned {
    background: #f8f8f8;
  }

  .action-cell {
    overflow: visible;
  }

  .ag-center-cols-clipper {
    border-left: 1px solid #BDC3C7;
    border-right: 1px solid #BDC3C7;
  }

  .ag-pinned-right-cols-container {
    .ag-row-position-absolute.ag-row-focus {
      z-index: 999; // fix to override   the dropdown  details get clipped in the grid column.
    }
  }
}

.ag-theme-bootstrap .ag-icon-checkbox-unchecked:before {
  color: $gray-500;
  font-size: 16px;
}

.ag-theme-bootstrap .ag-icon-checkbox-checked:before {
  color: $fda-blue;
  font-size: 16px;
}