// @import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:300,400,500,500i,600,700');

@font-face {
    font-family: "Roboto Condensed";
    src: url("assets/fonts/RobotoCondensed-Regular.ttf");
  }

  @font-face {
    font-family: "Roboto Condensed";
    src:   url("assets/fonts/RobotoCondensed-Bold.ttf");
    font-weight: bold;
}