// variables
$enable-shadows:            true !default;

/*
 * Typography
 * ======================================================================== */
$font-size-initial:       12px; // reset default browser value from 16px to 13px
$font-size-base:          1rem !default;
$font-size-lg:            1.25rem !default;
$font-size-sm:            0.875rem !default;
$font-size-larger:        1.1rem;
$font-size-mini:          0.9rem;
$font-size-index:         0.7rem;
$font-size-smaller:       85% !default;

$small-font-size:         85% !default;

$font-weight-bold:        700 !default;
$font-weight-semi-bold:   600 !default;
$font-weight-normal:      500 !default;
$font-weight-thin:        300 !default;
$font-weight-base:        $font-weight-normal !default;

$headings-font-weight:    $font-weight-normal !default;

$font-family-base:        Arial, Helvetica, sans-serif;
$font-family-sans-serif:  Arial, Helvetica, sans-serif;
$font-family-instructions: Arial, sans-serif;

$font-display-size:         2.5rem !default;
$font-display-lineheight:   3rem !default;
$font-h1-size:              2.25rem !default;
$font-h1-lineheight:        2.625rem !default;
$font-h2-size:              1.5rem !default;
$font-h2-lineheight:        1.875rem !default;
$font-h3-size:              1.125rem !default;
$font-h3-lineheight:        1.5rem !default;
$font-h4-size:              1rem !default;
$font-h4-lineheight:        1.375rem !default;
$font-question-size:        1.5rem !default;
$font-question-lineheight:  2rem !default;
$font-body-size:            1rem !default;
$font-body-lineheight:      1.5rem !default;
$font-bodysm-size:          0.875rem !default;
$font-bodysm-lineheight:    1.3125rem !default;
$font-bodyin-size:          1rem !default;
$font-bodyin-lineheight:    1.5rem !default;
$font-bodysmin-size:        0.875rem !default;
$font-bodysmin-lineheight:  1.3125rem !default;
$font-link-size:            1rem !default;
$font-link-lineheight:      1.5rem !default;

////////////////////////////////////////////////////////////////////////////////////
// Color system
//

$white: #fff !default;
$gray-100: #f5f5f5 !default;
$gray-200: #eeeeee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #868e96 !default;
$gray-700: #555 !default;
$gray-800: #343434 !default;
$gray-900: #242424 !default;
$black: #2E2925 !default;
$gray-light: #ddd !default;

$blue: #5d8fc2 !default;
$red: #dd5826 !default;
$yellow: #f0b518 !default;
$green: #64bd63 !default;
$cyan: #5dc4bf !default;

// fda theme colors
$fda-blue: #007CBA !default;
$fda-dark-blue: #222C67 !default;
$fda-header-dark-blue: #003152 !default;
$fda-medium-blue: #015A86 !default;
$fda-medium-dark-blue: #4757A0 !default;
$fda-light-blue: #D5E8F4 !default;
$fda-black: $black !default;
$fda-grey: #6E6D6D !default;
$fda-dark-grey: #6B6A6A !default;
$fda-blue-grey: #C5CBDD !default;
$fda-light-blue-grey: #F5F6F8 !default;
$fda-green: #0BA711 !default;
$fda-light-green: #EFFAF1 !default;
$fda-red: #D60036 !default;
$fda-dark-red: #BA0517 !default;
$fda-light-red: #FEE9E9 !default;
$fda-yellow: #E0B226 !default;
$fda-wizard-grey: #71748B !default;
$fda-white: #FFFFFF !default;
$fda-warn-yellow: #FFCC00 !default;
$fda-purple: #AE53FF !default;
$fda-internal-navbar: #34448D !default;

$fda-success-box: $fda-light-green !default;
$fda-footer: $fda-black !default;
$fda-navbar-active: #fff !default;
$fda-body-bg: $fda-light-blue-grey !default;
$fda-inline-error: $fda-red !default;

// Wizard color definitions
$wz-color-default: #E6E6E6 !default;
$wz-color-current: $fda-blue !default;
$wz-color-done: #339933 !default;
$wz-color-optional: #38ef38 !default;
$wz-color-editing: $fda-blue !default;

$fda-top-banner-height: 1.375rem;
$fda-app-top-navbar-height: 5.9375rem;
$fda-comp-header-height: 4.8125rem;
$fda-wiz-header-height: 5.375rem + 2.9375rem;
$fda-comp-footer-height: 8.75rem;
$fda-app-footer-height: 7.5rem;
$fda-internal-navbar-height: 4.625rem;
$fda-nonsigned-comp-to-deduct: $fda-top-banner-height + $fda-app-top-navbar-height + $fda-app-footer-height;
$fda-signed-comp-to-deduct: $fda-top-banner-height + $fda-app-top-navbar-height + $fda-comp-header-height + $fda-comp-footer-height + $fda-app-footer-height;
$fda-signed-compnohdr-to-deduct: $fda-top-banner-height + $fda-app-top-navbar-height + $fda-comp-footer-height + $fda-app-footer-height;
$fda-signed-wizcomp-to-deduct: $fda-top-banner-height + $fda-app-top-navbar-height + $fda-wiz-header-height + $fda-comp-footer-height + $fda-app-footer-height;
$fda-internal-height-to-deduct: $fda-top-banner-height + $fda-app-top-navbar-height + $fda-internal-navbar-height + $fda-app-footer-height;
$fda-content-min-width: 90%;
$fda-content-max-width: 95%;

$primary: $fda-blue;
$secondary: $gray-600;
$success: $green;
$primary-light: #dee4ee;
$success-light: #ecfaec;
$info-light: #f2fafa;
$warning-light: #fdf7e6;
$danger-light: #fff2ef;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$dark: $gray-800;
$inverse: $gray-700;
$gray: $gray-300;
$default: #f8f8f8;

$custom-theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $success,
  primary-light: $primary-light,
  success-light: $success-light,
  info-light: $info-light,
  warning-light: $warning-light,
  danger-light: $danger-light,
  info: $info,
  warning: $warning,
  danger: $danger,
  dark: $dark,
  inverse: $inverse,
  gray: $gray,
  default: $default
);

$jumbotron-padding: 2rem !default;

$link-color: $fda-blue; // #218bc3;

$text-color: $gray-700 !default;


$addition-bg: #f8f8f8 !default;

$body-bg: $fda-body-bg !default; // $fda-body-bg lightgray #f5f5f5 !default;
$body-bg-light: lighten($body-bg, 3%);

//== Components
//

$padding-base-vertical:     0.5rem !default;
$padding-base-horizontal:   1rem !default;

$padding-large-vertical:    0.75rem !default;
$padding-large-horizontal:  1.25rem !default;

$padding-small-vertical:    0.25rem !default;
$padding-small-horizontal:  0.75rem !default;

$margin-base-vertical:     0.5rem !default;
$margin-base-horizontal:   1rem !default;

$margin-large-vertical:    0.75rem !default;
$margin-large-horizontal:  1.25rem !default;

$margin-small-vertical:    0.25rem !default;
$margin-small-horizontal:  0.75rem !default;

$border-radius:          .3rem !default;
$border-radius-lg:       .35rem !default;
$border-radius-sm:       .25rem !default;

$nav-link-padding: 14px;

//== Sidebar
//
$sidebar-padding-horizontal: 11px;
$sidebar-width-open: 200px;
$sidebar-width-closed: 50px;
$sidebar-transition-time: 0.3s !default;
$sidebar-slim-scroll-width: 4px;
$sidebar-width: $sidebar-width-open + $sidebar-slim-scroll-width !default;

$sidebar-bg-color: #242424;
$sidebar-color: #aaa;

$sidebar-item-border-color: #2d2d2d;
$sidebar-item-active-color: #f2be35;
$sidebar-item-hover-bg-color: #111;

$chat-sidebar-bg-color: $gray-900 !default;
$chat-sidebar-width: 250px + $sidebar-slim-scroll-width !default;

$breadcrumb-divider: '/';
$breadcrumb-padding-y: 0;
$breadcrumb-bg: transparent;

$badge-font-weight: 600;

$widget-bg: #fff;
$widget-title-color: #444;
$widget-shadow: 0 0 10px darken($body-bg, 5%);


$input-btn-line-height: 1.5;

$input-btn-padding-y-sm: 0.4rem;
$input-btn-padding-x-sm: 0.8rem;

$input-height-small: 30px;

//== Navbar
//

$navbar-height:  50px !default;
$navbar-padding-y: 0 !default;
$navbar-padding-x: 0 !default;

$navbar-dashboard-bg:                $white !default;
$navbar-dashboard-color:             #777 !default;
$navbar-dashboard-border:            darken($navbar-dashboard-bg, 6.5%) !default;

// Navbar links
$navbar-dashboard-link-color:                #666 !default;

// Navbar links
$navbar-dashboard-link-hover-color:          #333 !default;
$navbar-dashboard-link-hover-bg:             transparent !default;
$navbar-dashboard-link-active-color:         #555 !default;
$navbar-dashboard-link-active-bg:            darken($navbar-dashboard-bg, 6.5%) !default;
$navbar-dashboard-link-disabled-color:       #ccc !default;
$navbar-dashboard-link-disabled-bg:          transparent !default;

// Navbar brand label
$navbar-dashboard-brand-color:               $navbar-dashboard-link-color !default;
$navbar-dashboard-brand-hover-color:         darken($navbar-dashboard-brand-color, 10%) !default;
$navbar-dashboard-brand-hover-bg:            transparent !default;

// Navbar toggle
$navbar-dashboard-toggle-hover-bg:           #ddd !default;
$navbar-dashboard-toggle-icon-bar-bg:        #888 !default;
$navbar-dashboard-toggle-border-color:       #ddd !default;


// Content
// -------------------------

$content-padding: 40px;
$content-padding-top: $content-padding + $navbar-height !default;

// Screens
// -------------------------

$screen-lg-height: 670px !default;


//== Buttons
//
$btn-default-bg: $addition-bg !default;
$btn-default-border: rgba(0, 0, 0, 0.15) !default;

// Dropdowns
$dropdown-link-color: $text-color;

// Widgets
// -------------------------
$widget-padding: 1.875rem !default;
$widget-padding-vertical: 15px !default;
$widget-padding-horizontal: 20px !default;
$widget-bg-color: $white;

//== Navs
//
$nav-tabs-active-link-hover-bg: $white !default;
$nav-tabs-active-link-hover-color: $gray-700 !default;

//== Forms
//
$input-border-focus: #4d90fe !default; //webkit focus color
$legend-color: lighten($text-color, 15%) !default;

$pre-bg: #f5f5f5 !default;

$cursor-disabled: not-allowed !default;

// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.

$state-success-text:             #3c763d !default;
$state-success-bg:               #dff0d8 !default;
$state-success-border:           darken($state-success-bg, 5%) !default;

$state-info-text:                #31708f !default;
$state-info-bg:                  #d9edf7 !default;
$state-info-border:              darken($state-info-bg, 7%) !default;

$state-warning-text:             #8a6d3b !default;
$state-warning-bg:               #fcf8e3 !default;
$mark-bg:                        $state-warning-bg !default;
$state-warning-border:           darken($state-warning-bg, 5%) !default;

$state-danger-text:              #a94442 !default;
$state-danger-bg:                #f2dede !default;
$state-danger-border:            darken($state-danger-bg, 5%) !default;

//*  Popover  */
$popover-bg: #ebeff1 !default;
$popover-arrow-outer-color: $popover-bg !default;
$popover-arrow-color: $popover-bg !default;

$brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
$pagination-active-border:             $brand-primary !default;
$panel-bg:                    #fff !default;
$panel-default-heading-bg:    #f5f5f5 !default;
$pagination-disabled-border:           #ddd !default;
$input-bg-disabled:              $gray-300 !default;
$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   lighten($gray-base, 33.5%) !default; // #555
$gray-light:             lighten($gray-base, 46.7%) !default; // #777
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee
$gray-semi-lighter:               $gray-300 !default;

//** Unit-less `line-height` for use in components like buttons.
$line-height-base:        1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed:    floor(($font-size-base * $line-height-base)) !default; // ~20px

$brand-primary:         darken(#428bca, 6.5%) !default; // #337ab7
$brand-success:         #5cb85c !default;
$brand-info:            #5bc0de !default;
$brand-warning:         #f0ad4e !default;
$brand-danger:          #D70037 !default; // #d9534f

$pagination-border:                    #ddd !default;
$pagination-hover-border:              #ddd !default;

$spacer-y: 1 em !default;

//== Grid variables =================================
//
$ag-grid-row-height: 40px !important;