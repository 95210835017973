/***********************/
/*    Animate.css      */
/***********************/

.animated{
  animation-duration: .5s;
}

/***********************/
/*    Font Awesome     */
/***********************/

.fa {
  vertical-align: middle;
}

.fa-sm {
  font-size: $font-size-sm;
}

.fa-lg {
  font-size: 1.2rem;
}

.btn > .fa,
.circle > .fa,
.input-group-text > .fa {
  vertical-align: baseline;
}

// .#{$fa-css-prefix}-spin-fast {
//   -webkit-animation: fa-spin 1s infinite linear;
//   -moz-animation: fa-spin 1s infinite linear;
//   -o-animation: fa-spin 1s infinite linear;
//   animation: fa-spin 1s infinite linear;
// }

/*************************************/
/*         @ngx-loading-bar          */
/*************************************/
ngx-loading-bar {
  position: relative;
  top: $navbar-height;
  left: 0;
  right: 0;
  display: block;
}

#loading-bar {
  pointer-events: none;
  transition: 350ms linear all;
}

#loading-bar .bar {
  transition: width 350ms;
  background: $primary;
  position: absolute;
  z-index: 1049;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
  border-bottom-right-radius: 1px;
  border-top-right-radius: 1px;
}

#loading-bar .peg {
  position: absolute;
  width: 70px;
  right: 0;
  top: 0;
  height: 2px;
  opacity: .45;
  box-shadow: $blue 1px 0 6px 1px;
  border-radius: 100%;
}

#loading-bar-spinner {
  position: absolute;
  color: $primary;
}

/*************************************/
/*           ngx-bootstrap           */
/*************************************/

.tab-container {
  display: block;
  background: none;
  border-radius: $border-radius;
  // box-shadow: $widget-shadow;
}



/**** Date Picker ******/

.bs-datepicker{
  .bs-datepicker-head {
    background-color: $fda-blue !important;
  }

  .bs-datepicker-body{
    table{
      td{
        span.selected,
        &.selected span,
        span[class*="select-"]:after,
        &[class*="select-"] span:after {
          background-color: $fda-blue !important;
        }

        &.week span {
          color: $fda-blue !important;
        }
      }
    } 
  }
}
