@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

@import "variables";
$theme-colors: map-merge($theme-colors, $custom-theme-colors);

@import "mixins";

@import "../../node_modules/bootstrap/scss/bootstrap";

@import "../../node_modules/animate.css/animate.css";
@import "../../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css";



@import "bootstrap-override";
@import "libs-override";
@import "grid-fonts";
@import "bootstrap-datepicker3.min";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/v4-shims.scss";

@import "ag-grid-community/dist/styles/ag-grid.css";
@import "ag-grid-community/dist/styles/ag-theme-bootstrap.css";


//
//end custom libs

//everything below this line is required for essential styling
@import "font";
@import "general";
@import "global-transitions";
@import "base";
@import "utils";
@import "estintake";
@import "busy";
@import "toastr-override";
@import "grid-override";
