
// override toastr css
.toast-success {
  border: $fda-green 1px solid !important;
  background-color: $fda-light-green !important;
  color: $fda-black !important;
}

.toast-warning {
  border: $fda-yellow 1px solid !important;
  background-color: #fcf0d1 !important;
  color: $fda-black !important;
}

.toast-message a,
.toast-message label {
  color: $fda-black !important;
}

.toast-success {
  background-image: url("../assets/img/check-circle-solid.png") !important;
  color: $fda-green !important;
}

.toast-warning {
  background-image: url("../assets/img/exclamation-triangle-solid.svg") !important;
}

.toast-top-center {
  top: 5rem !important;
}

.toast-title {
  color: $fda-black !important;
}


.toast-container .ngx-toastr{
  box-shadow: 0 0 5px #CCCCCC !important;
}

.toast-container.toast-top-center .ngx-toastr, .toast-container.toast-bottom-center .ngx-toastr{
  min-width: 500px;
}
